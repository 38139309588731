:root {
  --footer: #3f3f4b;
  --Primary-Blue: #000089;
  --Primary-BlueLight: #e7e7f2;
  --Primary-Rose: #ff006a;
  --Neutral-Middle-Grey: #666;
  --Neutral-Dark-grey: #3f3f4b;
  --bg: #f3f3f8;
  --swiper-pagination-bottom: 0px;
}
