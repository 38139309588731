.section-tries {
  margin-bottom: 169px;

  @media (max-width: 768px) {
    margin-bottom: 113px;
  }
}

.tries {
  &_mentions {
    margin-top: 22px;
    margin-bottom: 84px;
    align-self: center;
    flex: 0 1 100%;
    display: inline-block;
    width: 100%;
  }

  margin-top: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  // flex-wrap: wrap;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: calc(56px + 16px);
  }
}


