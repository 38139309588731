.btn {
  text-decoration: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-weight: bold;
  display: inline-flex;
  // padding: 14px 50px;
  padding: 14px 30px;

  gap: 10px;
  font-family: 'Cisco Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &-primary {
    color: white;
    background: var(--Primary-Blue);
    border: 2px solid var(--Primary-Blue);

    &:hover {
      background: var(--Primary-Rose);
      border: 2px solid var(--Primary-Rose);
    }
  }

  &-small{
  padding:  7px 20px 7px 20px!important;
  }

  &-reverse {
    color: white;
    background: var(--Primary-Rose);
    border: 2px solid var(--Primary-Rose);

    &:hover {
      background: var(--Primary-Blue);
      border: 2px solid var(--Primary-Blue);
    }
  }

  &-secondary {
    color: var(--Primary-Blue);
    background: transparent;
    border: 2px solid var(--Primary-Blue);

    &:hover {
      border: 2px solid var(--Primary-Blue, #000089);
      background: rgba(0, 0, 183, 0.15);
    }
  }

  &-simple {
    text-decoration: underline;
    color: white;
  }
}

.d-flex {
  display: flex;
}

.d-direction {
  &-column {
    flex-direction: column;
  }

  &-row {
    flex-direction: row;
  }
}

.d-justify {
  &-start {
    justify-items: flex-start;
  }

  &-center {
    justify-items: center;
  }

  &-end {
    justify-items: flex-end;
  }
}

.d-align {
  &-start {
    align-items: flex-start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }
}

.link {
  color: var(--Neutral-Middle-Grey);
  &-back {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0%;
  left: 0;
  width: 100%;
  color: white;
  padding: 14.25px 8px;
  gap: 10px;
  background: rgba($color: #000000, $alpha: 1);
  z-index: 999;

  @media (max-width: 992px) {
    flex-direction: column;

    & > * {
      flex: 0 1 100%;
    }
  }
}
