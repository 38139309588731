/*header*/

.header_container {
  // max-width: 1440px;
  padding: 0px 0px 0px 3.47222222222%;
  gap: 62px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: center;
    padding: 0px 0px 0px 16px;
  }
}

.header_animation {
  &-mobile {
    display: none;

    @media (max-width: 768px) {
      width: auto;
      max-width: 380px;
      display: block;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-end;
  }

  &.isAnimation {
    svg {
      @media (max-width: 768px) {
        display: none;
      }

      rect {
        opacity: 1;
        transform: translateX(0px);

        &:nth-of-type(1) {
          // 1
          transition: 0.15s all 0s ease-in;
        }

        &:nth-of-type(2) {
          // 4

          transition: 0.15s all 0.3s ease-in;
        }

        &:nth-of-type(3) {
          transition: 0.15s all 0.15s ease-in;
        }

        &:nth-of-type(4) {
          // 2
          transition: 0.15s all 0.5s ease-in;
        }
      }

      .prefix__cls-8 {
        opacity: 0.3;

        transform: translateX(0px);

        &:nth-of-type(1) {
          // 1
          transition: 0.15s all 0.5s ease-in;
        }

        &:nth-of-type(2) {
          // 4

          transition: 0.15s all 0.65s ease-in;
        }
      }

      .box-image {
        opacity: 1;

        transform: translateY(2px);

        &-1 {
          transition: 0.25s all 1.5s ease-in;
        }

        &-2 {
          transition: 0.25s all 1.25s ease-in;
        }

        &-3 {
          transition: 0.25s all 1s ease-in;
        }
      }
    }
  }

  overflow: hidden;

  & svg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 700px;
    width: auto;

    @media (max-width: 768px) {
      min-width: auto;
      width: 100%;
    }

    rect {
      opacity: 0;
      transition: all 0.25s ease-in;
      transform: translateX(100px);
    }

    .prefix__cls-8 {
      opacity: 0;
      transition: 0.5s all 1.25s ease-in;
      transform: translateX(-200px);
    }

    .box-image {
      position: relative;
      opacity: 0;

      transform: translateY(50px);
      transition: 0.25s all 1s ease-in;
    }
  }

  flex: 0 0 46.0416666667%;
  max-width: 663px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.header_txt {
  margin-left: calc(10.7638888889% - 3.47222222222%);
  flex: 0 1 38.8194444444%;
  // max-width: 559px;
  // flex: 0 1 559px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.header_txt-title {
  margin-bottom: 20px;
}

.header_txt-p + .header_txt-p {
  margin-top: 20px;
}

.header_txt-btn {
  margin-top: 20px;
}
