.row-steps {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 95px;
}

.step {
  flex: 0 1 33%;
  // flex: 0 1 280px;
  // gap: 55px;
  
  //  display: flex;
  //  justify-content: flex-start;
  //  flex-direction: column;
}

.step_header {
  // display: flex;
  // justify-content: center;
  position: relative;
}

.step_btn {
  margin-top: 15px;
}

.step_body-title {
  margin-top: 24px;
}

.step_header-img {
  height: 115px;
  width: auto;
}

.section-steps {
  padding-bottom: 185px;
  padding-top: 167px;

  @media (max-width: 768px) {
    padding-bottom: 185px;
    padding-top: 167px;
  }
}

.step_body-txt {
  padding-top: 15px;
}
