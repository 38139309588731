/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 19, 2024 */



@font-face {
    font-family: "Cisco Sans";
    src: url('ciscosans-bold-webfont.woff2') format('woff2'),
         url('ciscosans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: "Cisco Sans";
    src: url('ciscosans-webfont.woff2') format('woff2'),
         url('ciscosans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}