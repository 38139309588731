/*footer*/

.footer {
  padding: 20px 0 20px 0;
  background: var(--footer);
  color: white;
}

.footer_end {
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.footer_container {
  display: flex;
  gap: 20px;
  max-width: 1440px;
  padding: 0px 50px;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.linksFooter {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  @media (max-width: 768px) {
    gap: 5px;
  }
}

.linksFooter_link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  min-height: 40px;

}

.linksFooter_link-cookie{
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.linksFooter_link-mail {
  border: 1px solid white;
  border-radius: 30px;
  padding: 7px 20px;
  background: rgba(0,0,0,0);
  transition: .25s all;
  &:hover{
    background: rgba(0,0,0,.3);
  }
}

.footer_start {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-linksFooterEnd {
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  & .row_col {
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      text-align: center;
    }
  }

  .footer_start {
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
