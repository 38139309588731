/*top*/

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 31px;

  .section_container {
    // @media (max-width: 1440px) {
    padding: 0px 25px;
    // }
    &-noPadding {
      padding: 0px;
    }
  }
}

.top_imgtop {
  margin-bottom: 72px;
  width: 103px;
  height: auto;

  @media (max-width: 768px) {
    width: 75px;
    height: auto;
  }
}

.top_imgLogo {
  padding: 0 20px;
  max-width: 227px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 157px;
    padding: 0;
  }
}

.topHeader {
  &_back-wrapper {
    @media (max-width: 768px) {
      display: none;
    }
    &:empty {
      display: none;
    }
  }

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_back {
    text-decoration: none;
    transform: translateX(5px);
    transition: 0.25s all;
    &:hover {
      transform: translateX(0px);
    }
    &-mobile {
      display: none;
      @media (max-width: 768px) {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-top: 21px;
        margin-bottom: 29px;
      }
    }

    &-desktop {
      display: flex;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &_anchorNav {
    > button {
      cursor: pointer;
      position: relative;
      z-index: 2;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 36px;
        height: auto;
      }

      &[aria-expanded="true"] {
        background-color: var(--Primary-Blue);
        > img {
          filter: brightness(0) invert(1);
        }
      }

      @media (min-width: 1024px) {
        display: none;
      }

      @media (max-width: 1023px) {
        &[aria-expanded="false"] + ul {
          display: none;
        }
      }

    }

    > ul {
      display: flex;
      flex-flow: column nowrap;
      
      @media (max-width: 1023px) {
        position: absolute;
        background-color: #fff;
        margin-top: -30px;
        width: auto;
        right: 25px;
        left: auto;
        gap: 10px;
        padding: 30px 20px;
        border-radius: 30px;
        box-shadow: 0px 12px 22px 0px rgba(0, 0, 183, 0.1);
      }

      @media (min-width: 1024px) {
        flex-flow: row nowrap;
        align-items: center;
      } 

    }

    a {
      text-decoration: none;
      padding: 10px 20px;
      border-radius: 30px;
      background-color: transparent;
      color: var(--Neutral-Middle-Grey);
      transition-property: background-color, color;
      transition-duration: 0.2s;
      transition-timing-function: ease-in;
      border: solid 2px transparent;
      display: inline-block;

      &:hover {

        background-color: rgba(255,0,106,0.15);
        color: var(--Neutral-Dark-grey);

        @media (min-width: 1024px) {
          background-color: #fff;
        }

      }

      &.btn-primary {
        background-color: var(--Primary-Blue);
        color: #fff;
        font-size: 16px;
        line-height: inherit;

        &:hover { 
          background: rgba(0, 0, 183, 0.15);
          color: var(--Primary-Blue);
          border-color: var(--Primary-Blue);
        }
      }
    }

  }

}
