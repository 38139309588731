.ButtonContainer {
  position: fixed;
  bottom: 32px;
  right: 32px;
  align-items: center;
  height: 32px;
  width: 32px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  opacity: 0.5;
  background: #3b3b98;
  border-radius: 4px;
  transition: .25s all;
  display: none;
  @media (max-width: 768px) {
    display: none!important;
  }

  &.active {
    display: flex;
  }
  &:hover {
    opacity: 1;
  }
}
