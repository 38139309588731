/* autoprefixer grid: autoplace */

// Variable overrides second
@use 'styles/_vars.scss';
@use 'styles/_colors.scss';
@use 'styles/_mixins.scss';
//@import 'fonts/stylesheet.css';

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: 'Cisco Sans';
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  overscroll-behavior-y: contain;
}

button {
  border: none;
  outline: none;
  background: none;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

fieldset {
  border: none;
}

html,
body {
  padding: 0;
  margin: 0;
  background: var(--bg);
  // overflow-x: hidden;
  // overscroll-behavior-y: none;
}

html,
body,
#root {
  height: 100%;
}

img {
  display: block;
}

img.full {
  width: 100%;
}

/**/

.row {
  display: flex;

  &:not(.row-linksFooterEnd) {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.row_col {
  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
}

.row_col img {
  max-width: 100%;
}

.section-credits {
  padding-top: 53px;
  padding-bottom: 69px;
}

.section_title {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 70px;
  font-size: 24px;
  // text-transform: capitalize;
  font-weight: bold;
}

.section_titleBaseline {
  font-size: 24px;
  // text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 15px;
}

.section_baseline {
  color: var(--Neutral-Dark-grey, #3f3f4b);
}

.section_titleBottom {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 70px;
  font-size: 24px;
  // text-transform: capitalize;
  font-weight: bold;

  &:after {
    display: block;

    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    content: '';
    height: 10px;
    border-radius: 20px;
    width: 46px;
    background: var(--Primary-Blue, #000089);
    position: absolute;
  }
}

.section_container {
  padding: 0px 25px;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 992px) {
    padding: 0px 25px;
  }

  &-contain {
    max-width: 1440px;
  }
  &-job {
    padding: 0;
  }
}

// .section+.section {
//     padding-top: 25px;
//     padding-bottom: 25px;
// }

.display {
  &-h1 {
    font-family: 'Cisco Sans';
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 81px;
    /* 112.5% */

    @media (max-width: 992px) {
      /* Titles/Title Medium */

      font-size: 48px;
      line-height: 56px;
      /* 136.111% */
    }

    @media (max-width: 576px) {
      font-size: 36px;
      line-height: 49px;
      /* 136.111% */
    }
  }

  &-mention {
    /* Body/Body Small */
    font-family: 'Cisco Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }

  &-txt {
    font-family: 'Cisco Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 140% */
  }

  &-txtBold {
    font-family: 'Cisco Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &-txtBaseline {
    text-align: center;

    /* Labels/Label Large */
    font-family: 'Cisco Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    /* 135% */
  }

  &-suBTxtBold {
    font-family: 'Cisco Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &-Txtlittle {
    /* Body/Body Large */
    font-family: 'Cisco Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  &-TitleSection {
    text-align: center;

    font-family: 'Cisco Sans';

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    /* 109.375% */
  }
}

.t {
  &-bold {
    font-weight: 700;
  }
}

.c {
  &-pink {
    color: var(--Primary-Rose);
  }

  &-blue {
    color: var(--Primary-Blue);
  }

  &-middleGrey {
    color: var(--Neutral-Middle-Grey);
  }

  &-darkGrey {
    color: var(--Neutral-Dark-grey);
  }
}

// component

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  // min-height: 393px;
  position: relative;
  padding: 27px 34px 19px 24px;
  border-radius: 20px;
  background: #fff;
  //max-width: 393px;
  // min-height: 360px;
  // display: inline-flex;
  // flex-direction: column;


  /* blur_cards */
  box-shadow: 0px 12px 22px 0px rgba(0, 0, 183, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 26px;

    width: 32px;
    height: 14px;
    transform: translate(-50%, -50%) rotate(-90deg);
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--Primary-Rose, #ff006a);
  }

  &_title {

    &-oneLine{
      min-height: auto;
    }
    margin-bottom: 15px;
    // min-height: 55px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &_txt {
   
    margin-top: 27px;
    // min-height: 134px;

   
font-size: 16px;
font-weight: 400;
line-height: 21.79px;
text-align: left;

  }

  &_btn {

  }
}

// linkvideo

.linkVideo {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 386px;
  z-index: 1;
  border-radius: 20px;
  overflow: hidden;

  &:hover {
    .linkVideo_icon {
      transform: translate(-50%, -50%) scale(1.1);

      transition: 0.25s all;
    }
  }
  &:active {
    .linkVideo_icon {
      transform: translate(-50%, -50%) scale(1.25);
      transition: 0.25s all;
    }
  }

  &_img {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  &_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 60px;
    min-height: 60px;
    transform: translate(-50%, -50%) scale(1);

    transform-origin: center center;
    transition: 0.25s all;
  }
}

.tooltip {
  &_icon {
    width: 24px;
    height: 24px;
    color: var(--Primary-Rose);
  }
}

//

.header {
  &-job {
    margin-top: 135px;
    margin-bottom: 174px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1200px) {
      display: block;
    }
  }

  // position: relative;
  &_title {
    gap: 27px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // flex-direction: column;

    &-img {
      @media (max-width: 768px) {
        height: 73px;
        width: auto;
      }
    }

    &-txt {
      //styleName: Display/Display Medium;
      font-size: 64px;
      font-weight: 700;
      line-height: 68px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 137, 1);
      flex: 0 1 100%;
      padding-right: 15px;

      @media (max-width: 768px) {
        max-width: 236px;

        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
  &_tags {
    flex: 0 0 100%;
    gap: 15px;
    margin-left: 156px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      margin-left: 90px;
      margin-right: 25px;
      margin-bottom: 25px;
      margin-top: 25px;
    }
  }

  &_body {
    position: relative;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
      margin-top: 41px;
    }

    &-1 {
      @media (max-width: 768px) {
        max-width: 344px;
      }
    }
    &-2 {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(0%, 50%);

      @media (max-width: 768px) {
        height: 73px;
        width: auto;
      }
    }
  }
}

.player-wrapper {
  display: flex;
  justify-content: center;
  height: auto !important;

  @media (max-width: 768px) {
    width: 100%;
    max-height: 100%;
    height: 100%;
  }

  // max-height: 500px;
}

.react-player {
  overflow: hidden;
  border-radius: 20px;
  // max-width: 70vw;
  max-height: 60vh;
  height: auto !important;
  aspect-ratio: 9/16;

  @media (max-width: 768px) {
    // width: 100%;
    max-height: 100vh;
    // height: 100%;
  }
}

/**/
*:focus,
*:focus-visible {
  outline: 0;
}

/**/

table {
  border-collapse: collapse;
  border-spacing: 0;

  color: #333;
  background: white;
  border: 1px solid grey;
  font-size: 14px;
}
table thead th,
table tfoot th {
  // word-wrap: break-word;
  // word-break:break-all;
  color: #777;
  background: rgba(0, 0, 0, 0.1);
}

table th,
table td {
  word-wrap: break-word;
  // word-break:break-all;
  padding: 0.5em;
  border: 1px solid lightgrey;
}

.banner {
  &_txt {
    padding: 0 50px;
    line-height: 24px;

    @media (max-width: 768px) {
      padding: 25px 15px;
    }
  }
}
