.section-working{}

.section_titleBottom-working{
line-height: 40px;
}

.workingCards{
  margin-top: 90px;
  margin-bottom: 162px;
  gap: 92px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 768px) {
    gap: 110px;
  }
  
}
.workingCard{
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 90px;

  @media (max-width: 768px) {
    gap: 24px;
    flex-wrap: wrap;
  }


  &_image{
    position: relative;
  }


  &_number{
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 31px;
    width: 160px;
    height: 80px;
    top: -40px;
    left: 33px;
    text-align: right;
    border-radius: 50px;
    background: #000089;
    color: #FFFFFF;
    //styleName: Display/Display Small;
    // font-family: Cisco Sans;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    text-align: center;
  }
 
  &_containerImage{
    overflow: hidden;
    object-fit: cover;
    width: 360px;
    height: 200px;
    background: linear-gradient(54.15deg, rgba(0, 0, 137, 0.4) 0%, rgba(255, 0, 106, 0.4) 100%);
    border-radius: 20px;

    @media (max-width: 768px) {
      width: 328px;
      height: 200px;
    }




  }
  &_img{

  }
  &_description{

  }

  &_txt{
    color: #383838;

    //styleName: Labels/Label Large;

font-size: 20px;
font-weight: 700;
line-height: 27px;
text-align: left;



  }

  &_separator{

    width: 69px;
  
  
  
  display: block;
  
   
      margin-top: 24px;
      height: 10px;
      border-radius: 20px;
  
      background: var(--Primary-Blue, #000089);
     
  
  }

}