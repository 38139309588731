.section-partenaires {
  padding-top: 90px;
  padding-bottom: 90px;
  background: var(--Primary-BlueLight);
}

.row-parteners {
  gap: 90px;
  margin-bottom: 68px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: row !important;
    justify-content: space-evenly;
  }
}

.parteners_img{
  display: block;
  max-height: 150px;
  width: auto;
}

.row_col-parteners {
  display: flex;
  // max-width: 229px;
  @media (max-width: 768px) {
    max-width: 102px;
    flex: 0 1 50%;

    justify-content: center;
  }
}
