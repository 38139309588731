.section-who {
  // margin-bottom: 221px;
}

.infos {
  // max-width: 1120px;
  margin-top: 132px;
  margin-bottom: 118px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 104px;

  @media (max-width: 1200px) {
    margin-top: 62px;
    gap: 24px;
  }

  @media (max-width: 1049px) {
    // margin-top: 5px;
    margin-bottom: 70px;
  }

  &_item {
    gap: 16px;
    display: flex;
    // flex: 0 0 50%;

    @media (max-width: 1049px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 35px;
    }
  }
}

.infoTxt {
  &_header {
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;

    @media (max-width: 1200px) {
      gap: 10px;
    }

    @media (max-width: 965px) {
      margin-bottom: 35px;
    }

  
  }

  &_title {
    //styleName: Labels/Label Large;
    font-family: Cisco Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;

    color: rgba(255, 0, 106, 1);
  }

  &_subtitle {
    //styleName: Labels/Label Large;
    font-family: Cisco Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 12px;

    color: rgba(56, 56, 56, 1);
  }
  &_txt {
    //styleName: Body/Body Large;
    // max-width: 275px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(102, 102, 102, 1);
  }
  &_video {
    width: 100%;
    flex: 0 0 217px;
    max-width: 217px;

    @media (max-width: 1049px) {
      margin-bottom: 93px;
      margin-top: 35px;
      // flex: 0 0 100%;
      // max-width: 100%;
    }
  }
}

.testimonals {
  // max-width: 1120px;
  width: 100%;

  &_header {
    justify-content: flex-start;
    gap: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  &_title {
    //styleName: Labels/Label Large;
    font-family: Cisco Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;

    color: rgba(255, 0, 106, 1);
  }

  &List {
    gap: 21px;
    width: 100%;

    &-mobile {
      display: none !important;
      @media (max-width: 965px) {
        display: flex !important;
      }
    }

    &-desktop {
      display: flex !important;
      @media (max-width: 965px) {
        display: none !important;
      }
    }

    // &_container {
    //   display: block;
    //   width: 100%;
    //   overflow-x: auto;
    //   overflow-y: hidden;
    // }

    & > * {
      flex: 0 1 calc(100% / 5);
      overflow-x: hidden;
    }

    &_title {
      overflow-x: hidden;

      //styleName: Labels/Label Large;
      font-family: Cisco Sans;
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      color: rgba(0, 0, 137, 1);
    }

    &_video {
      margin-bottom: 39px;
      margin-top: 27px;
    }

    &_caption {
      position: relative;
      z-index: 1;
      background: rgba(255, 255, 255, 1);
      font-family: Cisco Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(102, 102, 102, 1);
      padding: 21px;

      @media (max-width: 965px) {
        box-shadow: 0px 12px 22px 0px rgba(0, 0, 183, 0.1);
      }

      &-txt {
        /**Major Pxroperties**/
        // overflow: hidden;
        line-height: 2rem;
        // max-height: 8rem;
        // -webkit-box-orient: vertical;
        display: block;
        // display: -webkit-box;
        // overflow: hidden !important;
        // text-overflow: ellipsis;
        // -webkit-line-clamp: 4;
      }

      @media (max-width: 965px) {
        box-shadow: initial;
      }

      border-radius: 20px;

      &-before {
        z-index: -1;
        position: absolute;
        left: 15px;
        top: 0px;
        transform: translate(0%, -50%);
      }
    }
  }
}

.swiper {
  height: 100%;
  width: 100%;
  padding-bottom: 50px !important;
  position: relative;
  // margin-left: calc(326.5px/2)!important;
  // left: 250px;
  &-slide {
    width: 50%;
    // &:first-child{
    //   // margin-left:  calc(326.5px/2);
    // }

    // left: calc((100% + 120px) / 5);
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: rgba(188, 188, 188, 1);
    cursor: pointer;
    transition: 0.3s ease width;

    &-active {
      width: 25px;
      cursor: auto;
      background: var(--Primary-Rose);
    }
  }
}

.swiper {
  &_container {
    width: 100%;
    //  margin-left: 25%;
    &-inner {
      margin-left: -25%;

      overflow: hidden;
    }
  }
}
